import React from 'react';
import db from "../../Firestore";
import { ValidatorForm} from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import { withRouter} from 'react-router-dom';
import SearchInput from "../material-ui/SearchInput";
import {Select} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import env from "../../conf";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dates from "../../services/Dates";
import moment from "moment";
import ClientInfo from "./ClientInfo";
import Switch from "@material-ui/core/Switch";
import CustomDate from "../shared/CustomDate";
import Hidden from "@material-ui/core/Hidden";
import Services from "../../services/Services";

class AddAppt extends React.Component {

    state = {
        client: this.props.appointment.client !== undefined ? this.props.appointment.client : {},
        services: [],
        service_cat: env.service_cat,
        status: this.props.new ? 1 : this.props.appointment.status, // approved by default
        selected_cat: 0,
        selected_service: env.service_cat['default_indexes_'+env.env][0][0],
        selected_flag: env.service_cat.flag['0'],
        appt: {},
        client_selected: this.props.appointment.client !== undefined,
        apt_key: this.props.new ? null : this.props.appointment.uid,
        delete: false,
        min_time: '',
        infoActive: false,
        loading: false,
        deposit: false,
        filler: false,
        payment_type: 0,
        user: Services.getUser(),
        resourceId: this.props.appointment.resourceId ? this.props.appointment.resourceId : env.default_employee,
        less_than_24: false,
        location: this.props.lc
    };

    fullset = env.service_cat.flag['0'];
    refill = env.service_cat.flag['1'];
    service_to_display = [];
    excluded_indexes = env.service_cat['exclude_flag_indexes_' + env.env];
    yesterday = moment().add(1, 'day').startOf('day').toDate();
    tomorrow = moment().subtract(1, 'days').startOf('day').toDate();
    minTime = new Date().setHours(8, 0, 0);

    clearRedirect = () => {
        this.setState({
            client: {},
            deposit: false,
            appt: {},
            client_selected: false,
            query: '',
            selected_service: '',
            selected_cat: 0,
            apt_key: null,
            selected_tag: env.service_cat.flag['0'],
            loading: false
        });
        this.props.close();
    };

    componentDidMount() {
        this.setState({
            user: Services.getUser(),
            appt: {
                location: this.state.location
            }
        })
        this.getservices();
    };

    handleListChange = event => {

        let name = event.target.name;
        let value = event.target.value;

        if(name !== 'payment_type' && name !== 'location'){

            let di = this.state.service_cat['default_indexes_'+env.env];

            if(name === 'selected_cat'){
                this.setState({
                    selected_flag: value !== 0 ? null : this.fullset,
                    selected_service: value !== 0 ? di[value] : di[0][0]
                })
            }else if(name === 'selected_flag'){
                this.setState({
                    selected_service: value === 'fullset' ? di[0][0] : di[0][1]
                })
            }/*else if(name === 'status'){
                this.setState({
                    filler: value === 2
                })
            }*/
        }

        this.setState({
            [name]: value
        },cb => {
           console.log(this.state)
        });
    };

    handleSwitch = (e) => {
        this.setState({
            [e.target.name]: !JSON.parse(e.target.value),
            payment_type: e.target.name === 'deposit' ? 0 : this.state.payment_type
        });
    };

    handleChange = (value, flag) => {

        let apt = {...this.state.appt};
        apt[flag] = value;

        this.setState({
            appt: apt
        });
    };

    renderServices(){
        return this.service_to_display = this.state.services.filter(r => {

            let service = this.state.service_cat.cat_name[this.state.selected_cat];
            let cat_name = r.cat_name;

            if(this.state.selected_cat === 0 && this.excluded_indexes.indexOf(r.index) < 0){
                return cat_name.toLowerCase() === service.toLowerCase() && (r.flag === this.state.selected_flag || r.name === 'Lash Lift' || r.name === 'Lash Removal' || r.name === 'Lash Bath');
            }

            return cat_name.toLowerCase() === service.toLowerCase()
        });
    }

    getClient = client => {

        this.setState({
            client: client,
            client_selected: true
        });

    };

    getservices = () => {
        db.collection('/services')
            .orderBy('price', 'asc')
            .get()
            .then(res => {
                this.setState({
                    services: res.docs.map((doc, index) => {
                        return {
                            ...doc.data(),
                            uid: doc.id,
                            index: index
                        }
                    })
                }, () => {
                    this.getAppointment();
                    //console.log(this.state.services)
                })
            })
    };

    getAppointment = () => {

        let apt = this.props.appointment;

        apt.start = Dates.isDateValid(apt.start) ? apt.start: apt.start.toDate();
        apt.end = Dates.isDateValid(apt.end) ? apt.end: apt.end.toDate();
        let min_time = moment(apt.start).format("hh:mm:ss a");

        let less_than_24 = !this.isEditable(env.restrictions.cancellation)

        if(!this.props.new && apt.status !== 2) {
            this.getClient(apt.client);

            let selected_cat = this.state.service_cat.cat_name.indexOf(apt.service.cat_name);
            let selected_service = 0;
            this.state.services.forEach((item, index) => {

                if(item.cat_name === 'Lashes'){
                    if(item.name === apt.service.name && item.flag === apt.service.flag){
                        selected_service = index;
                    }
                }else{
                    if(item.name === apt.service.name){
                        selected_service = index;
                    }
                }
            });

            this.setState({
                selected_flag: apt.service.flag,
                selected_cat: selected_cat,
                selected_service: selected_service,
                client: apt.client,
                client_selected: true,
                deposit: apt.deposit || false,
                payment_type: apt.payment_type,
                resourceId: apt.resourceId,
                less_than_24: less_than_24,
                location: apt.location
            })
        }/*else{ // filler appointment
            if(apt.status === 2)
                this.setState({
                    filler: true
                })
        }*/


        this.setState({
            appt: apt,
            min_time: min_time
        }, cb => {
            //console.log(this.state)
        })
    };

    handleSubmit = () => {

        if((this.state.client_selected && this.state.status !== 2) || this.state.status === 2){
            if(this.state.appt.start > this.state.appt.end){
                alert('End date & time cannot be before the start date & time');
            }else{

                let selectied_serv = this.state.selected_service;

                this.setState({
                    loading: true
                }, cb => {

                    let isNew = this.props.new;

                    let payload = {
                        start: this.state.appt.start,
                        end: this.state.appt.end,
                        status: this.state.status,
                        created_at: isNew ? moment().toDate() : moment(this.state.appt.created_at).toDate(),
                        created_from: isNew? this.state.location : this.state.appt.location,
                        location: isNew? this.state.location : this.state.appt.location
                    };

                    if(this.state.status !== 2){

                        let service =  this.state.services[selectied_serv];
                        delete service.index;

                        payload.service = service;

                        payload.client = {
                            name: this.state.client.name ? this.state.client.name : this.state.client.first_name + ' ' + this.state.client.last_name,
                            email: this.state.client.email ? this.state.client.email : '',
                            id: this.state.client.uid ? this.state.client.uid : this.state.client.id,
                            member: this.state.client.member ? this.state.client.member : null,
                            membership: this.state.client.membership ? this.state.client.membership : null,
                            phone: this.state.client.phone ? this.state.client.phone : null,
                            credit: this.state.client.member ? this.state.client.credit : 0
                        };

                        payload.payment_type = this.state.payment_type || 0;
                        payload.deposit = this.state.deposit;
                    }

                    payload.resourceId = this.state.resourceId;

                    //console.log('will add ', payload);

                    if(isNew){ // New Appointment

                        if(payload.location !== Services.getLocation()){
                            alert('Error: Location mismatch! Please change the Manager Location in order to create an appointment');
                            this.setState({
                                loading: false
                            })
                        }else{
                            db.collection('/appoitments').add(payload).then((ref) => {

                                if(payload.deposit){ // adding the deposit as a income in case of paid deposit

                                    let income = {
                                        payment_type: payload.payment_type,
                                        service_date: payload.created_at, // TODO if this date is in future it won't show up on income list until it pass, fix it to get linked with the right dates
                                        type: 'deposit',
                                        amount: env.deposit,
                                        client:payload.client,
                                        created_at: payload.created_at,
                                        apt_id: ref.id,
                                        location: payload.location
                                    };


                                    db.collection('/incomes')
                                        .add(income)
                                        .then(() => {
                                            this.clearRedirect();
                                        })
                                }else{
                                    this.clearRedirect();
                                }

                            })
                        }


                    }else{ // updating an existing appointment


                        if(payload.location !== this.state.location){ // state location is the new one updated by the selected menu
                            if(window.confirm('Are you sure you would like to change this appointment location?')){
                                // this is for the invalid date, and no need to update this field when updating an appointment
                                delete payload.created_at;
                                payload.location = this.state.location

                                db.collection('/appoitments')
                                    .doc(this.state.apt_key)
                                    .set(payload, { merge: true })
                                    .then(() => {
                                        //console.log('done updating');
                                        this.clearRedirect();
                                    })
                            }else{
                                this.setState({
                                    loading: false
                                })
                            }
                        }else{
                            // same as previous [if] note
                            delete payload.created_at;
                            payload.location = this.state.location

                            db.collection('/appoitments')
                                .doc(this.state.apt_key)
                                .set(payload, { merge: true })
                                .then(() => {
                                    //console.log('done updating');
                                    this.clearRedirect();
                                })
                        }
                    }
                })
            }
        }else{
            alert('error: Please select a client!')
        }
    };

    deleteAppt = () => {
        this.setState({
            delete: !this.state.delete
        })
    };

    handleDeleteAppt = () => {
        db.collection('/appoitments').doc(this.state.apt_key).delete().then(() => {
            this.clearRedirect();
        })
    };

    addIncome = () => {

        let apt_id = this.state.apt_key;
        this.clearRedirect();
        this.props.income(apt_id);

    };

    toggleTab = (e) => {
        this.setState({
            infoActive: e
        })
    };

    goToClient = (id) => {
        this.props.goToClient(id);
    };

    isEditable = (config, name) => {
        let diff = moment(this.state.appt.start).diff(moment(),'hours', true)

        if(this.state.user.owner){
            return true
        }

        return diff > config
    }

    render(){
        return(
            <Card>
                <CardContent>
                    {
                        this.state.delete ?
                            <div className="delete-dialogue">
                                <Grid container className="mt-3 mb-4 text-center">
                                    <Grid item xs={12}>
                                        Are you sure you want to delete <b>{this.state.client.name}</b>'s appointment?<br/>
                                        <small className="dgray-color">- This is a final action and cannot be reverted! -</small>
                                    </Grid>
                                </Grid>

                                <Grid container className="mt-3 mb-1">
                                    <Grid item xs={6}>
                                        <Button onClick={this.handleDeleteAppt} variant="contained" color="secondary">
                                            <Icon className="mr-2">delete_sweep</Icon>
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} className="text-right">
                                        <Button onClick={this.deleteAppt}  className='link gray-color text-decoration-none'>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                            : <div>
                                <Button onClick={this.clearRedirect} className='col-xs-3 link float-right'>
                                    <Icon style={{color: '#bbb', float: 'right'}}>close</Icon>
                                </Button>
                                <h4 className={'col-xs-9 ' + (this.props.new ? 'form-title' : 'form-title tabs')} >
                                    <span className={this.state.infoActive ? '' : 'active'} onClick={event => this.toggleTab(false)}>
                                        {this.props.new ? 'New' : ''} Appointment
                                    </span>
                                    {
                                        !this.props.new ?
                                            <span className={this.state.infoActive ? 'active' : ''} onClick={event => this.toggleTab(true)}>Client Info</span>
                                            : null
                                    }
                                </h4>
                                <div className="clearfix"></div>

                                { !this.state.infoActive ?
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                        onError={errors => console.log(errors)}
                                    >
                                        <Grid container>
                                            {
                                                this.state.status !== 2 ?
                                                    <Grid item xs={8} className="dgray-color pt-3">
                                                        <SearchInput
                                                            client={this.getClient}
                                                            status={this.state.client_selected}
                                                            selectedClient={this.state.client}
                                                            change={this.isEditable(env.restrictions.client_edit, 'client name')}
                                                            user={this.state.user.owner}
                                                        />
                                                    </Grid> : null
                                            }
                                            <Grid item xs={4} className="dgray-color pt-3 pr-3 text-right">
                                                <Select
                                                    value={this.state.status}
                                                    name='status'
                                                    onChange={this.handleListChange}
                                                    disabled={!this.isEditable(env.restrictions.appt_status_edit, 'apt status')}
                                                >
                                                    {
                                                        Object.keys(env.appt_status).map((key, index) => {
                                                            return this.props.new || (this.state.status !== 2 && parseInt(key) !== 2 && !this.props.new)?
                                                             <MenuItem key={index} value={parseInt(key)}>{env.appt_status[key]}</MenuItem>: null
                                                        })
                                                    }
                                                </Select>
                                            </Grid>

                                        </Grid>
                                        {
                                            this.state.status !== 2 ?
                                                <div>
                                                    <Grid container className="my-2 my-sm-3">
                                                        <Grid item xs={3} sm={3} className="dgray-color">
                                                            Service Category:
                                                        </Grid>
                                                        <Grid item xs={3} sm={3}>
                                                            <Select
                                                                value={this.state.selected_cat}
                                                                name='selected_cat'
                                                                onChange={this.handleListChange}
                                                                disabled={!this.isEditable(env.restrictions.apt_service_edit, 'service')}
                                                            >
                                                                {
                                                                    this.state.service_cat.cat_name.map((item, index) => {
                                                                        return <MenuItem key={index} value={index}>{item}</MenuItem>
                                                                    })
                                                                }
                                                            </Select>
                                                        </Grid>

                                                        <Grid item xs={3} sm={3} className="dgray-color text-right">
                                                            Assign to:
                                                       </Grid>
                                                        <Grid item xs={3} sm={3} className='text-right pr-3'>
                                                            <Select
                                                                value={this.state.resourceId}
                                                                name='resourceId'
                                                                onChange={this.handleListChange}
                                                                disabled={!this.isEditable(env.restrictions.assigned_to_edit, 'assigned')}
                                                            >
                                                                {
                                                                    env.employees.map((item, index) => {
                                                                        return <MenuItem key={index} value={item.id}>{item.title}</MenuItem>
                                                                    })
                                                                }
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        this.state.selected_cat === 0 && this.excluded_indexes.indexOf(this.state.selected_service) < 0 ?
                                                            <Grid container className="my-2 my-sm-3">
                                                                <Grid item xs={3} sm={4} className="dgray-color">
                                                                    <span className="mt-2 d-block">Type:</span>
                                                                </Grid>
                                                                <Grid item xs={9} sm={8}>
                                                                    <FormControlLabel
                                                                        value='fullset'
                                                                        checked={this.state.selected_flag === this.fullset}
                                                                        name='selected_flag'
                                                                        control={<Radio color="secondary"/>}
                                                                        label="FullSet"
                                                                        labelPlacement="end"
                                                                        onChange={this.handleListChange}
                                                                        disabled={!this.isEditable(env.restrictions.apt_service_edit, 'service')}
                                                                    />
                                                                    <FormControlLabel
                                                                        value='refill'
                                                                        checked={this.state.selected_flag === this.refill}
                                                                        name='selected_flag'
                                                                        control={<Radio color="secondary"/>}
                                                                        label="Refill"
                                                                        labelPlacement="end"
                                                                        onChange={this.handleListChange}
                                                                        disabled={!this.isEditable(env.restrictions.apt_service_edit, 'service')}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            : null
                                                    }
                                                    <Grid container className="my-0 my-sm-3">
                                                        <Grid item xs={3} sm={4} className="dgray-color">
                                                            Service:
                                                        </Grid>
                                                        <Grid item xs={9} sm={8}>
                                                            <Select
                                                                value={this.state.selected_service}
                                                                name='selected_service'
                                                                onChange={this.handleListChange}
                                                                disabled={!this.isEditable(env.restrictions.apt_service_edit, 'service')}
                                                            >
                                                                {
                                                                    this.renderServices().map((doc) => {
                                                                        return (
                                                                            <MenuItem key={doc.uid} value={doc.index}>{doc.name}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                </div> : null
                                        }

                                        <Grid container className="my-2 my-sm-3">
                                            <Grid item xs={4} sm={4} className="dgray-color pt-3">
                                                Start Date & Time:
                                            </Grid>
                                            {
                                                this.state.appt.start < moment().subtract(env.restrictions.date_time_edit, 'hours') && !this.state.user.owner?
                                                    <DatePicker
                                                        selected={this.state.appt.start}
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        customInput={<CustomDate />}
                                                        minTime={this.minTime}
                                                        disabled
                                                    />:
                                                    (
                                                        //this.state.less_than_24 && !this.state.user.owner ?
                                                        this.isEditable(env.restrictions.cancellation, 'date') ?
                                                            <Grid item xs={8} sm={8} className='mt-2'>
                                                                <DatePicker
                                                                    selected={this.state.appt.start}
                                                                    onChange={e => this.handleChange(e, 'start')}
                                                                    showTimeSelect
                                                                    timeFormat="HH:mm"
                                                                    timeIntervals={30}
                                                                    disabledKeyboardNavigation
                                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                                    timeCaption="time"
                                                                    customInput={<CustomDate />}
                                                                    shouldCloseOnSelect={true}
                                                                />
                                                            </Grid>
                                                            : <Grid item xs={12} sm={8} className='mt-2 pt-1'>
                                                                <DatePicker
                                                                    minDate={this.yesterday}
                                                                    maxDate={this.tomorrow}
                                                                    selected={this.state.appt.start}
                                                                    onChange={e => this.handleChange(e, 'start')}
                                                                    showTimeSelect
                                                                    timeFormat="HH:mm"
                                                                    timeIntervals={30}
                                                                    disabledKeyboardNavigation
                                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                                    timeCaption="time"
                                                                    customInput={<CustomDate />}
                                                                    shouldCloseOnSelect={true}
                                                                />
                                                            </Grid>)
                                            }
                                        </Grid>
                                        <Grid container className="my-0 my-sm-3">
                                            <Grid item xs={4} sm={4} className="dgray-color pt-3">
                                                End Time:
                                            </Grid>
                                            <Grid item xs={8} sm={8} className='mt-2 pt-1'>
                                                {
                                                    this.state.appt.start < moment().subtract(env.restrictions.date_time_edit, 'hours') && !this.state.user.owner?
                                                        <DatePicker
                                                            selected={this.state.appt.end}
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            customInput={<CustomDate />}
                                                            disabled
                                                        /> :
                                                        (this.isEditable(env.restrictions.cancellation, 'date') ?
                                                        <DatePicker
                                                            selected={this.state.appt.end}
                                                            onChange={e => this.handleChange(e, 'end')}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={30}
                                                            disabledKeyboardNavigation
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            timeCaption="time"
                                                            customInput={<CustomDate />}
                                                            shouldCloseOnSelect={true}
                                                        />
                                                        :<DatePicker
                                                                minDate={this.yesterday}
                                                                maxDate={this.tomorrow}
                                                                selected={this.state.appt.end}
                                                                onChange={e => this.handleChange(e, 'end')}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={30}
                                                                disabledKeyboardNavigation
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                timeCaption="time"
                                                                customInput={<CustomDate />}
                                                                shouldCloseOnSelect={true}
                                                            />)
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid container className="my-0 my-sm-3">
                                            <Grid item xs={4} sm={4} className="dgray-color pt-3">
                                                Location:
                                            </Grid>
                                            <Grid item xs={8} sm={8} className='mt-2 pt-1'>
                                                <Select
                                                    value={this.state.location}
                                                    name='location'
                                                    onChange={this.handleListChange}
                                                >
                                                    {
                                                        Object.keys(env.locations).map((keyName, index) => {
                                                            return <MenuItem key={index} value={keyName}>{env.locations[keyName].name}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        {
                                            this.state.status !== 2 ?
                                                <Grid container className="my-3">
                                                    <Grid item xs={6} className="dgray-color pt-3">
                                                        <FormControlLabel className='row' control={
                                                            <Switch
                                                                checked={this.state.deposit}
                                                                onChange={this.handleSwitch}
                                                                value={this.state.deposit}
                                                                name="deposit"
                                                                inputProps={{'aria-label': 'secondary checkbox'}}
                                                                disabled={!this.isEditable(env.restrictions.deposit_edit, 'deposit')}
                                                            />
                                                        } label="Paid deposit?"/>
                                                    </Grid>
                                                    {
                                                        this.state.deposit && this.state.status !== 2 ?
                                                            <Grid item xs={6} className="dgray-color pt-3">
                                                                <Select
                                                                    value={this.state.payment_type}
                                                                    name='payment_type'
                                                                    onChange={this.handleListChange}
                                                                    disabled={!this.isEditable(env.restrictions.deposit_edit, 'deposit')}
                                                                >
                                                                    {
                                                                        env.accounting.expense_types.map((item, idx) => {
                                                                            return <MenuItem key={idx} value={idx}>{item}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </Grid>
                                                            : null
                                                    }
                                                </Grid> : null
                                        }

                                        <Grid container className="mt-1 mt-sm-5">
                                            <Grid item xs={12} sm={6}>
                                                {
                                                    this.state.loading ?
                                                        <small>Loading...</small>
                                                        : <Button type="submit" variant="contained" className="mr-2">
                                                            <Icon className="mr-2">{this.props.new ? 'add' : 'check'}</Icon>
                                                            {this.props.new ? 'Add New' : 'Update'}
                                                        </Button>
                                                }
                                                {
                                                    !this.props.new && !this.state.loading?
                                                        Dates.isUpcoming(this.state.appt.start) ?
                                                            <Button onClick={this.deleteAppt} variant="contained"
                                                                    color={"secondary"}>
                                                                <Icon className="mr-2">delete_sweep</Icon>
                                                                Delete
                                                            </Button>
                                                            : this.state.user.owner ?
                                                                <Button onClick={this.addIncome}>
                                                                    <Icon className="mr-2">money</Icon>
                                                                    Add Income
                                                                </Button>
                                                            : null
                                                        : ''
                                                }
                                            </Grid>
                                            <Hidden only='xs'>
                                                <Grid item xs={6} className="text-right p-2">
                                                    <Button onClick={this.clearRedirect}
                                                            className='link gray-color text-decoration-none'>
                                                        Close
                                                    </Button>
                                                </Grid>
                                            </Hidden>
                                        </Grid>
                                    </ValidatorForm>
                                    : <ClientInfo client={this.state.client} goToClient={this.goToClient} />
                                }
                            </div>
                    }
                </CardContent>
            </Card>
        )
    }
}

export default withRouter(AddAppt);