import React from 'react';
import db from "../../Firestore";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import myStyles from "../../layouts/Styles";
import Modal from '@material-ui/core/Modal';
import AddAppt from "./AddAppt";
import env from "../../conf";
import { withRouter} from 'react-router-dom';
import Loading from "../material-ui/Loading";
import Services from "../../services/Services";
import AptReadOnly from "./AptReadOnly";


class ApptList extends React.Component {

    state = {
        appointments: [],
        events: [],
        modal: false,
        new_appt: true,
        selected_appt: {},
        loading: false,
        dfStart: moment().startOf('week').toDate(),
        dfEnd: moment().endOf('week').toDate(),
        view: env.calendar.default,
        scroll_to: new Date(moment().format('L') +' ' + env.calendar.default_time),
        user: Services.getUser(),
        location: this.props.lc
    }

    today = new Date();
    min = new Date(
            this.today.getFullYear(),
            this.today.getMonth(),
            this.today.getDate(),
            env.calendar.min_view
        )

    max = new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate(),
        env.calendar.max_view
    )


    apptRef = db.collection('/appoitments');
    localizer = BigCalendar.momentLocalizer(moment);

    resources = env.employees; // TODO emp[loyee move later to a separate table

    componentWillUnmount() {
        //this.unsubscribe();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        //console.log('will receive mount location ', nextProps.lc)

        this.setState({
            location: nextProps.lc
        }, r => {
            this.getAppointmentsByWindow('first');
        })
    }

    getAppointmentsByWindow = (stat) => {

        stat = stat || null;
        let start = this.state.dfStart || null;
        let end = this.state.dfEnd || null;

        let query = start == null || end == null ? this.apptRef : this.apptRef.where('start' , '>=', start)

        this.setState({
            loading: stat === 'first'
        }, cb => {
            return query.onSnapshot( res => {

                let the_list = res.docs.filter(dc => {
                    let my_end =  moment(dc.data().end.toDate()).toDate();
                    return my_end <= end;
                }).filter(apt => {
                    return apt.data().location === this.state.location;
                })

                const events = the_list.map(doc => {
                    let info = doc.data();
                    let start_date = moment(info.start.toDate()).toDate();
                    let end_date = info.end ? moment(info.end.toDate()).toDate() : moment(start_date).add(1, 'hours').toDate();
                    info.uid = doc.id;


                    let event = {
                        title: info.status === 2 ? 'Break' : info.client.name,
                        start: start_date,
                        end: end_date,
                        resource: info,
                        resourceId: info.resourceId,
                        bgColor: '#000'
                    };

                    return event
                })

                this.setState({
                    events: events
                    }, cb => {
                    this.setState({
                        loading: false
                    });
                })

            });
        })
    }

    componentDidMount() {
        this.getAppointmentsByWindow('first');

    }


    addNew = () => {
        this.setState({
            modal: true,
            new_appt: true,
            selected_appt: {
                start: new Date(),
                end: new Date(),
                uid: null
            }
        })

    };

    handleSelection = e => {
        if(!Services.isTech(this.state.user)){ // only if allowed people can create apt
            this.setState({
                modal: true,
                new_appt: true,
                selected_appt: {
                    start: e.start,
                    end: e.end,
                    resourceId: e.resourceId
                }
            })
        }
    };

    handleModalClose = e => {
        this.setState({
            modal: false,
            new_appt: true,
            selected_appt: {}
        })
    };

    handleEventSelection = e => {
        this.setState({
            modal: true,
            new_appt: false,
            selected_appt: e.resource
        })
    };

    handleAddingIncome = (id) => {
        this.props.income(id);
    };

    handleStyles = (event, start, end, isSelected) => {

        let deposit_class, member_class, cat_class = '';
        let status = event.resource.status

        if(status === 1){
            deposit_class = event.resource.deposit ? ' deposited' : '';
            member_class = event.resource.client.member ? ' member' : '';
            cat_class = event.resource.service.cat_name.replace(/\s/g, '').toLowerCase();
        }else{
            deposit_class = '';
            member_class = '';
            cat_class = status === 2 ? 'filler' : env.appt_status[status].toLowerCase()
        }

        return {
            className: 'event-' + cat_class + deposit_class + member_class
        }
    };

    ViewChange = (e) => {

        let start, end, view;

        if(e.start && e.end){
            start = e.start;
            end = e.end;
            view = 'month'
        }else{
            if(e.length === 1){ // day view
                start = e[0];
                end = moment(e[0]).endOf('day').toDate();
                view = 'day';
            }else{ // week view
                start = e[0];
                end = moment(e[6]).endOf('day').toDate();
                view = 'week'
            }
        }

        this.setState({
            dfStart: start,
            dfEnd: end,
            view: view
        }, cb => {
            this.getAppointmentsByWindow()
        })

    }

    scrollToTime = (e) => {
        //console.log(e)
    }

    goToClient = (id) => {
        this.handleModalClose()
        this.props.goToClient(id);
    };

    render(){
        return(
            this.state.loading ?
                <div className='mt-5'>
                    <Loading />
                </div>
                : <div className="clients-list m-3" style={{height: '600px'}}>
                    <BigCalendar
                        localizer={this.localizer}
                        events={this.state.events}
                        startAccessor="start"
                        endAccessor="end"
                        popup={true}
                        defaultView={env.calendar.default}
                        selectable={true}
                        onSelectSlot={this.handleSelection}
                        onSelectEvent={this.handleEventSelection}
                        eventPropGetter={this.handleStyles}
                        onRangeChange={this.ViewChange}
                        resources={this.resources}
                        views={['month', 'week', 'day']}
                        min={this.min}
                        max={this.max}
                    />
                    {
                        !this.state.modal && !Services.isTech(this.state.user) ?
                            <div className="col-sm-6 col-xs-12 float-right mt-4 mb-4" style={{textAlign: 'right'}}>
                                <Fab onClick={this.addNew} color="primary" style={myStyles.fab_appointments} aria-label="Add">
                                    <Icon>add</Icon>
                                </Fab>
                            </div> : null
                    }
                    <div className="clearfix"></div>

                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.modal}
                        onClose={this.handleModalClose}
                    >
                        <div className='my_modal col-xs-12'>
                            {
                                Services.isTech(this.state.user) ?
                                    <AptReadOnly
                                        close={this.handleModalClose}
                                        appointment={this.state.selected_appt}
                                        goToClient={this.goToClient}
                                    />
                                    : <AddAppt
                                        new={this.state.new_appt}
                                        close={this.handleModalClose}
                                        appointment={this.state.selected_appt}
                                        income={this.handleAddingIncome}
                                        goToClient={this.goToClient}
                                        lc={this.state.location}
                                        min={this.min}
                                        max={this.max}
                                    />
                            }
                        </div>
                    </Modal>
                </div>
        )
    }
}

export default withRouter(ApptList);
