import React from 'react';
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableLine from "./TableLine";
import {Paper} from "@material-ui/core";

class Hr extends React.Component {

    list = {
        hr: {
            head: ['Area', 'Description', 'Price', 'Sessions'],
            line1: ['Small Area', 'Upper lip, cheeks, hair line, jawline, chin, armpits, hands, toes', 650, '6 Sessions'],
            line2: ['Medium Area', 'Neck, chest, shoulders, half arms, belly line, bikini line, lower back', 950, '6 Sessions'],
            line3: ['Large Area', 'Full back, full legs, Brazilian, full belly, full butt', 1250, '6 Sessions'],
            line4: ['Back Acne Facial', '', 150, '']
        },
        membership: {
            head: ['Membership', 'Description', 'Price'],
            option1: ['Small Area', '1 Session /mo', 199],
            option2: ['Medium Area', '1 Session /mo', 299],
            option3: ['Large Area', '1 Session /mo', 599],
        }
    };

    render() {
        return(
            <div>
                <Paper className='mx-3 mt-1 mb-3'>
                    <Table>
                        <TableHead>
                            <TableLine list={this.list.hr.head} head={true} />
                        </TableHead>
                        <TableBody>
                            <TableLine list={this.list.hr.line1} head={false} />
                            <TableLine list={this.list.hr.line2} head={false} />
                            <TableLine list={this.list.hr.line3} head={false} />
                            <TableLine list={this.list.hr.line4} head={false} />
                        </TableBody>
                    </Table>
                </Paper>

                <Paper className='mx-3 mt-1 mb-3'>
                    <Table>
                        <TableHead>
                            <TableLine list={this.list.membership.head} head={true} membership={true}/>
                        </TableHead>
                        <TableBody>
                            <TableLine list={this.list.membership.option1} head={false} />
                            <TableLine list={this.list.membership.option2} head={false} />
                            <TableLine list={this.list.membership.option3} head={false} />
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }
}

export default Hr;
