import React from 'react';
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableLine from "./TableLine";
import {Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

class Eyebrow extends React.Component {

    list = {
        wax: {
            head: ['Wax', 'Price'],
            list : [
                ['Lip', 10],
                ['Chin', 10],
                ['Nasal', 10],
                ['Face', 30]
            ]
        },
        eyebrow: {
            head: ['Eyebrow', 'Price'],
            list : [
                ['Lamination', 150],
                ['Ombré Powder', 350],
                ['Tint & Shape', 95],
                ['Brow Wax', 25]
            ]
        },
        membership: {
            head: ['Membership', 'Description', 'Price'],
            members: ['Brow Wax Bar', 'One Eyebrow Service /mo', 80]
        }
    };

    render() {
        return(
            <div>
                <Paper className='mx-3 mt-1 mb-3'>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6}>
                            <Table>
                                <TableHead>
                                    <TableLine list={this.list.wax.head} head={true} />
                                </TableHead>
                                <TableBody>
                                    {
                                        this.list.wax.list.map(it => {
                                            return <TableLine list={it} head={false} />
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Table>
                                <TableHead>
                                    <TableLine list={this.list.eyebrow.head} head={true} />
                                </TableHead>
                                <TableBody>
                                    {
                                        this.list.eyebrow.list.map(it => {
                                            return <TableLine list={it} head={false} />
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper className='mx-3 mt-1 mb-3'>
                    <Table>
                        <TableHead>
                            <TableLine list={this.list.membership.head} head={true} membership={true}/>
                        </TableHead>
                        <TableBody>
                            <TableLine list={this.list.membership.members} head={false} />
                        </TableBody>
                    </Table>
                </Paper>

            </div>
        )
    }
}

export default Eyebrow;
